@import "Constants";

.message-tile {
  font-family: "UD Digi Kyokasho NP-B", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: grid;
  grid-template-areas:
    "header  header"
    "button content";
  background-color: $towaPurple;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 1em;
  grid-template-rows: 1fr;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 1em;
  max-width: 40vw;
  min-width: 600px;
  height: 600px;
  margin: 1%;
  flex-grow: 1;
  font-weight: bold;

  &.message-tile--Bibi {
    background-image: url("bg1.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    -webkit-text-stroke-color: $towaBlack;

    //text-shadow:
    //        -2px -2px 0 $towaBlack,
    //        2px -2px 0 $towaBlack,
    //        -2px 2px 0 $towaBlack,
    //        2px 2px 0 $towaBlack;
  }

  &.message-tile--Towa {
    background-image: url("bg2.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 700px;
    color: black;
    text-shadow:
            -1px -1px 0 #FFF,
            1px -1px 0 #FFF,
            -1px 1px 0 #FFF,
            1px 1px 0 #FFF;
    -webkit-text-stroke-color: white;

    .message-tile__message {
      align-self: end;
    }
  }


  &__message {
    grid-area: header;
    -webkit-text-stroke-width: 1px;
  }

  &__signature {
    grid-area: content;
    justify-self: end;
    align-self: end;
    -webkit-text-stroke-width: 1px;
  }

  &__button {
    grid-area: button;
    height: 2em;
    width: 2em;
    background-color: $towaYellow;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $towaPink;
    font-size: 1.2em;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    -webkit-text-stroke: unset !important;

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
}
