@import "Constants";

.credits{
  &__button{
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: $towaPurple; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */

  }

  &__pane{
    position: fixed; /* Fixed/sticky position */
    bottom: 0; /* Place the button at the bottom of the page */
    z-index: 80;
    background-color: #7b6ba9;
    width: 100%;

  }

  &__content{
    padding: 1em;
  }
}
